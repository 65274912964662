export default {
    // 返回GET传值
    GET() {
        const url = window.decodeURI(window.location.href);
        const urlArgsStr = url.split("?")[1];
        const urlArgsGroup = urlArgsStr.split("&");
        const result = {};
        urlArgsGroup.forEach(uag => {
            const uagArray = uag.split("=");
            const key = uagArray[0];
            const value = uagArray[1] || null;
            result[key] = value;
        });
        return result;
    },
    split() {
        const urlWithoutArgs = window.location.href.split("?")[0];
        const urlArray = urlWithoutArgs.split("://");
        const levels = urlArray[1].split("/");
        const domain = levels[0].split(":")[0];
        const port = levels[0].split(":")[1] || "80";
        return {
            head : urlArray[0],
            port,
            levels,
            domain
        }
    }
}