const BaseURL = window._CONFIG_.baseURL;

export default {
    // 发送ajax请求
    ajaxPost(subURL, data = {}) {
        const formData = new FormData();
        for(let key in data) {
            formData.append(key, data[key]);
        }
        return window.axios.post(BaseURL + subURL, formData);
    },
    // 从结果中查找
    find(data, key, desireType = "") {
        if(typeof desireType !== "string") {
            console.error("[CONSTANT.find] Desire type has to be string typed.");
            return;
        }
        if(!data) return null;
        let result = null;
        const fi = obj => {
            for(let child in obj) {
                if(key === child) {
                    if(!desireType) {
                        result = obj[key];
                        return;
                    }
                    if(desireType && desireType === typeof result) {
                        result = obj[key];
                        return;
                    } 
                }
                if(typeof obj[child] === "object") {
                    fi(obj[child]);
                }
            }
        }
        fi(data);
        return result;
    },
    // 查找所有
    findAll(data, key, desireType = "") {
        if(typeof desireType !== "string") {
            console.error("[CONSTANT.find] Desire type has to be string typed.");
            return;
        }
        if(!data) return null;
        const result = [];
        const fi = obj => {
            for(let child in obj) {
                if(key === child) {
                    if(!desireType) {
                        result.push(obj[key]);
                        return;
                    }
                    if(desireType && desireType === typeof result) {
                        result.push(obj[key]);
                        return;
                    } 
                }
                if(typeof obj[child] === "object") {
                    fi(obj[child]);
                }
            }
        }
        fi(data);
        return result;
    },
    clone(obj) {
        return JSON.parse(JSON.stringify(obj));
    },
    // 运行环境
    runtime : (() => {
        const ua = navigator.userAgent;
        if(ua.includes("Android") || ua.includes("Linux")) return "AND";
        if(ua.includes("iPhone") || ua.includes("iOS")) return "IOS";
        if(ua.toLowerCase(ua).includes("ipad")) return "IPD";
        return "OTH";
    })(),
    // 记录行为日志
    setUserLog(typeCode, content) {
        this.ajaxPost("UserLog/SetLog.php", {
            typeCode,
            content : encodeURIComponent(content)
        });
    }
}