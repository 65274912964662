<template>
    <div class="point-list" v-if="CONSTANT.runtime === 'OTH'">
        <h3>美国大学排名TOP200</h3>
        <div class="list">
            <div class="item" v-for="(item, itemIndex) in pointList" :key="itemIndex"
                @click="flyTo($event.target, item)">
                <div class="rank">{{ item.Rank }}</div>
                <h3>{{ decodeURIComponent(item.Title_ZH) }}</h3>
                <h4 v-if="item.Title_EN">{{ decodeURIComponent(item.Title_EN) }}</h4>
            </div>
        </div>
    </div>
</template>

<script>
import DOMFlash from "../../../utils/DOMFlash.js";

export default {
    name : "PointList",
    computed : {
        pointList() {
            const result = this.CONSTANT.clone(this.$parent.pointList);
            result.sort((a, b) => a.Rank - b.Rank);
            return result;
        }
    },
    methods : {
        flyTo(evTarget, pointInfo) {
            DOMFlash(evTarget).show(500, () => {
                this.$parent.map.flyTo(new window.BMapGL.Point(pointInfo.Lng - -.3, pointInfo.Lat - .15), 11); 
                this.$parent.panelShow(pointInfo);
            });
        }
    }
}
</script>

<style scoped>
.point-list {
    position : absolute;
    right : 20px; top : 10px;
    width : 250px; height : 400px;
    background-color : rgba(43, 43, 43, .8);
    z-index : 100;
    border-radius: 8px;
    overflow : hidden;
}
.point-list > h3 {
    position : relative; margin : 0 auto;
    color : #fff; font-size : 14px;
    text-align: center;
    height : 32px; line-height: 32px;
    background-color : rgba(0,0,0,.2);
    font-weight: 700;
}

.list {
    position : absolute;
    left : 0; right : -17px; top : 32px; bottom : 0;
    overflow-x : hidden; overflow-y: scroll;
}
.list > .item {
    position : relative; margin : 0 auto;
    border-bottom : 1px solid rgba(255,255,255,.3);
    cursor : pointer;
    opacity: .8;
}
.list > .item:hover {
    opacity : 1;
    background-color : rgba(0,0,0,.1);
}
.list > .item > * {
    pointer-events :none;
}
.list > .item > .rank {
    position : absolute;
    width : 26px; height : 26px; border-radius: 13px;
    background-color : rgba(0,0,0,.2);
    left : 12px; top : 50%; transform: translateY(-50%);
    line-height: 26px; text-align: center;
    color : rgba(255,255,255,.6);
    font-size : 14px;
    font-style: italic;
    font-weight: 700;
}
.list > .item > h3 {
    position : relative; font-size : 14px;
    margin : 10px 10px 0 50px;
    display : block;
    color : #fff;
    font-weight: 700;
}
.list > .item > h4 {
    position : relative; font-size : 12px;
    margin : 0 10px 10px 50px;
    display : block;
    color : #fff;
    font-weight: 700;
}
</style>