<template>
    <div class="app page" @contextmenu.prevent>
        <div class="map" ref="map"></div>
        <!-- 左上角控件 -->
        <div class="control" :class="{ 'no-ctrl' : ARGS.noctrl === '1', 'control-mobile' : CONSTANT.runtime !== 'OTH' } ">
            <button @click="zoomBack($event.target)" v-if="ARGS.noctrl !== '1'">返回大地图</button>
            <button @click="zoomBack($event.target)" v-if="ARGS.noctrl === '1'">返回当前学校</button>
            <button @click="toOriginal($event.target)" v-if="ARGS.noctrl === '1'">返回全地图</button>
        </div>
        <!-- top列表 -->
        <PointList v-if="ARGS.noctrl !== '1'"></PointList>
        <HimeLoading v-if="loading"></HimeLoading>
    </div>
</template>

<script>
import ARGS from "./mixins/ARGS.js";
import Map from "./mixins/Map.js";

import HimeLoading from "../modules/HimeLoading.vue";
import DOMFlash from "../utils/DOMFlash.js";

import PointList from "./components/PointList/PointList.vue";

export default {
    name: 'App',
    mixins : [
        ARGS, Map
    ],
    components : {
        HimeLoading,
        PointList
    },
    data() { return {
        loading : false
    }},
    mounted() {
        if(this.ARGS.noctrl === "1") return;
        this.CONSTANT.setUserLog("0", `访客打开【名校地图】（所用大区链接【${ window.location.href }】）。`);
    },
    methods : {
        toOriginal(evTarget) {
            if(!this.ARGS["original-z"]) {
                alert("大区数据异常，请联系管理员！");
                return;
            }
            // const $a = document.createElement("a");
            // const currentHrefArr = window.location.href.split("?");
            // const currentHrefHead = currentHrefArr[0];
            // $a.href = currentHrefHead + "?z=" + this.ARGS["original-z"];
            // // $a.target = "_blank";
            // $a.click();
            DOMFlash(evTarget).show(500, () => {
                let [lng, lat, zoomLevel] = this.ARGS["original-z"].split("_");
                this.map.centerAndZoom(new window.BMapGL.Point(lng, lat), zoomLevel); 
            });
        }
    }
}
</script>

<style>
* {
    margin : 0; padding : 0;
    font-weight: 100;
    -webkit-font-smoothing: antialiased;
    /* user-select: none; */
}
html, body {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -moz-osx-font-smoothing: grayscale;
}
.page {
    position : fixed;
    left : 0; right : 0; top : 0; bottom : 0;
    overflow : hidden;
    background-color : #fff;
}
/*
.BMap_Marker.BMap_noprint {
    box-shadow : 0 0 10px rgba(97, 97, 97, 0.5) inset,0 0 30px rgba(80, 80, 80, 0.5)!important;
    border-radius : 100px;
}
/* .BMap_Marker.BMap_noprint:hover {
    box-shadow : 0 0 10px #cc4c33 inset,0 0 30px #cc4c33!important;
} 

@keyframes ani-spark {
    0% {
        opacity : 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
*/
</style>

<style scoped>
.map {
    position : absolute;
    left : 0; right : 0; top : 0; bottom : -40px;
}
.control {
    position : absolute;
    z-index : 100;
    left : 20px; top : 10px; height : 32px; line-height: 32px;
}
.control.no-ctrl {
    left : auto; right : 20px;
}
.control.control-mobile {
    left : 50%; right : auto; top : auto; bottom : 20px; transform: translateX(calc(-50% - 6px));
    width : 100%;
    text-align: center;
}
.control > button {
    position : relative; display : inline-block;
    background-color : #2b2b2b; color : #fff;
    border : none;
    padding : 0 12px;
    height : 32px; line-height: 32px;
    border-radius: 8px;
    opacity: .6;
    cursor : pointer;
    font-weight: 900;
    margin-left : 12px;
}
.control > button:hover {
    opacity: .8;
}
</style>
