import HimeURL from "../../utils/HimeURL.js";

export default {
    methods : {
        getARGS() {
            this.ARGS = HimeURL.GET();
            this.CONSTANT.ARGS = this.ARGS;
        },
    },
    data() { return {
        ARGS : []
    }},
    mounted() {
        this.getARGS();
    }
}