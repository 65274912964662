<template>
    <div class="hime-loading" :style="{ opacity }">
        <div class="panel">
            <div class="dot" v-for="(d, dIndex) in dotList" :key="dIndex"
                :style="{
                    transform : 'translate(' + d.x + 'px, ' + d.y + 'px)',
                    opacity : d.opacity
                }"></div>
        </div>
    </div>
</template>

<script>
import HimeEase from "../utils/HimeEase.js";

export default {
    data() { return {
        dotList : [
            { x: 0, y: -15, opacity : .7 },
            { x: 10, y: -10, opacity : .6 },
            { x: 15, y : 0, opacity : .5 },
            { x: 10, y : 10, opacity : .4 },
            { x: 0, y : 15, opacity : .3 },
            { x: -10, y : 10, opacity : .2 },
            { x: -15, y : 0, opacity : .1 },
            { x: -10, y : -10, opacity : 0 },
        ],
        clock : null,
        opacity : 0
    }},
    beforeMount() {
        this.clock = setInterval(() => {
            this.dotList.forEach(dot => {
                if(dot.opacity >= .7) {
                    dot.opacity = 0;
                } else {
                    dot.opacity += .1;
                }
            })
        }, 200);
    },
    beforeUnmount() {
        clearInterval(this.clock);
    },
    mounted() {
        HimeEase(this, "opacity").to(1, 300);
    }
}
</script>

<style scoped>
.hime-loading {
    position : fixed; z-index : 1000;
    left : 0;
    top : 0;
    width : 100%;
    height : 100%;
    background-color : rgba(0,0,0,.3);
}
.panel {
    position: absolute;
    left : 50%;
    top : 50%;
    width : 60px;
    height : 60px;
    background-color: #eee;
    border-radius: 8px;
    margin-left : -30px;
    margin-top : -30px;
    opacity : .8;
}
.dot {
    position : absolute;
    left : 50%;
    top : 50%;
    height : 10px;
    width : 10px;
    background-color : #2b2b2b;
    border-radius: 5px;
    margin-left : -5px;
    margin-top : -5px;
}
</style>