/**
 * 作者 : Sakura-Hime
 * HimeEase 动画库
 * 最新修订日期 20191227
 */
/* eslint no-console: ["error", { allow: ["log", "warn", "error"] }] */

export default function(parent, keyName, easeConfig = {}) {

    // 检查入参
    if(typeof parent !== "object" || isNaN(parent[keyName])) {
        console.error(`Property "${ keyName }" of binding object is not a number.`);
        return;
    }

    const config = {
        fps : isNaN(easeConfig.fps) ? 60 : easeConfig.fps
    }

    const animation = {

        // 直线前往
        to(dest, duration) {
            if(isNaN(dest) || isNaN(duration)) {
                console.error(`${ dest } or ${ duration} are not numbers.`);
                return;
            }
            // 按60fps推定分解长度
            const frameDuration = 1000 / config.fps;
            // 创建map
            const timeMap = [0];
            while(timeMap[timeMap.length - 1] < duration) {
                timeMap.push(timeMap[timeMap.length - 1] - frameDuration * -1);
            }
            // 比较数据变化，并等距分解
            const stepLength = (dest - parent[keyName]) / timeMap.length;
            // 状态位
            const handle = new Promise(resolve => {
                // 播放动画
                timeMap.forEach((step, stepIndex) => {
                    setTimeout(() => {
                        parent[keyName] -= stepLength * -1;
                        // 最后一个动作
                        if(stepIndex === timeMap.length - 1) {
                            parent[keyName] = dest * 1;
                            if(typeof resolve === "function") resolve();
                        }
                    }, step);
                });
            });
            return handle;
        },

        // 折返
        toggle(dest, duration, sleep = 0) {
            if(isNaN(dest) || isNaN(duration)) {
                console.error(`${ dest } or ${ duration} are not numbers.`);
                return;
            }
            if(isNaN(sleep)) {
                console.error(`${ sleep } is not a number.`);
                return;
            }
            const orig = parent[keyName];
            this.to(dest, duration / 2).then(() => {
                setTimeout(() => {
                    this.to(orig, duration / 2);
                }, sleep);
            });
        },

        // 从起点前往
        fromTo(start, dest, duration) {
            if(isNaN(start) || isNaN(dest) || isNaN(duration)) {
                console.error(`${ start }, ${ dest } or ${ duration} are not numbers.`);
                return;
            }
            parent[keyName] = start;
            return this.to(dest, duration);
        },

        // 按动作表执行
        byStep(stepMap) {
            if(!Array.isArray(stepMap)) {
                console.error("Step map is invalid.");
                return;
            }
            const handle = new Promise(resolve => {
                let stepIndex = 0;
                const selfCalling = stepIndex => {
                    this.to(stepMap[stepIndex].dest, stepMap[stepIndex].duration).then(() => {
                        stepIndex++;
                        if(stepMap.length === stepIndex) {
                            if(typeof resolve === "function") resolve();
                        }
                        if(stepMap[stepIndex]) {
                            selfCalling(stepIndex);
                        }
                    });
                }
                selfCalling(stepIndex);
            });
            return handle;
        },

        // 弹动前往
        bounceTo(dest, duration) {
            if(isNaN(dest) || isNaN(duration)) {
                console.error(`${ dest } or ${ duration} are not numbers.`);
                return;
            }
            // 提取30%的时间用于弹跳
            const bounceDuration = duration * .3;
            const subBounceDuration = duration * .2;
            const toDuration = duration * .5;
            // 单步跨度
            const stepLength = dest - parent[keyName];
            // 动作表
            const stepMap = [
                // 完成初始动作
                { dest, duration : toDuration },
                // 超越终点向右移动20%的距离
                { dest : dest - stepLength * -.2, duration : bounceDuration * .25},
                // 返回终点
                { dest, duration : bounceDuration * .25},
                // 缩回起点前20%的距离
                { dest : dest - stepLength * .2, duration : bounceDuration * .25},
                // 返回终点
                { dest, duration : bounceDuration * .25},
                // 进行弱弹动， 向右移动8%的距离
                { dest : dest - stepLength * -.08, duration : subBounceDuration * .25},
                // 返回终点
                { dest, duration : subBounceDuration * .25},
                // 缩回弹动8%的距离
                { dest : dest - stepLength * .08, duration : subBounceDuration * .25},
                // 返回终点
                { dest, duration : subBounceDuration * .25}
            ];
            return this.byStep(stepMap);
        },

        // 弹跳往返
        bounceToggle(dest, duration, sleep = 0) {
            if(isNaN(dest) || isNaN(duration)) {
                console.error(`${ dest } or ${ duration} are not numbers.`);
                return;
            }
            if(isNaN(sleep)) {
                console.error(`${ sleep } is not a number.`);
                return;
            }
            const orig = parent[keyName];
            this.bounceTo(dest, duration / 2).then(() => {
                setTimeout(() => {
                    this.bounceTo(orig, duration / 2);
                }, sleep);
            });
        }

    }

    return animation;
}