/**
 * 作者 : Sakura-Hime
 * HimeEase 动画库 扩展功能
 * 给某个dom增加闪光特效
 * 最新修订日期 20200202
 */

/* eslint no-console: ["error", { allow: ["log", "warn", "error"] }] */
import HimeEase from "./HimeEase.js";

const DOMFlashStyle = `
    <style id="dom-flash-style">
        .dom-flash-panel {
            position : absolute;
            left : 0;
            top : 0;
            right : 0;
            bottom : 0;
            overflow : hidden;
        }
        .dom-flash-skew {
            width : 40px;
            height : 100%;
            transform : skew(45deg, 0);
            transform-origin : left top;
            background-color : rgba(255,255,255,.8);
            position : absolute;
            top : 0;
        }
    </style>
`;
window.document.head.innerHTML += DOMFlashStyle;

export default function(DOM) {

    // 创建斜向滑块放入DOM
    const panel = window.document.createElement("div");
    const skew = window.document.createElement("div");
    panel.setAttribute("class", "dom-flash-panel");
    skew.setAttribute("class", "dom-flash-skew");
    DOM.appendChild(panel);
    panel.appendChild(skew);
    
    // 左侧距离
    Object.defineProperty(skew, "__DFLeft", {
        set : val => {
            skew.style.left = val + "px";
        },
        get : () => {
            return parseFloat(skew.style.left) * 1;
        }
    });
    skew.__DFLeft = -40;
        
    return {
        show(duration = 500, callback) {
            setTimeout(() => {
                const panelWidth = panel.offsetWidth;
                HimeEase(skew, "__DFLeft").to(panelWidth + 40, duration).then(() => {
                    panel.removeChild(skew);
                    DOM.removeChild(panel);
                    if(typeof callback === "function") callback();
                });
            }, 0);
        }
    }
}