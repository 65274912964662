import DOMFlash from "@/utils/DOMFlash.js";

export default {
    methods : {
        init() {
            this.initMap();
            this.renderPoints();
        },
        initMap() {
            let [lng, lat, zoomLevel] = this.ARGS.z.split("_");
            const result = new window.BMapGL.Map(this.$refs.map, {
                maxZoom : this.ARGS["max-z"] || 100,
                minZoom : this.ARGS["min-z"] || 0
            });
            result.centerAndZoom(new window.BMapGL.Point(lng, lat), zoomLevel); 
            result.enableScrollWheelZoom(true);  
            this.map = result;
            this.map.addEventListener("click", ev => {
                // 确定用户是否点到了地图上，万一他点图标上了，则是另外一番操作了
                if(ev.target.getAttribute("id") !== "mask") return;
                // 关闭所有的标签
                if(this.currentLabel) {
                    this.map.removeOverlay(this.currentLabel);
                    this.currentLabel = null;
                }
            });
        },
        renderPoints() {
            return this.CONSTANT.ajaxPost("Map/GetPointList.php").then(echo => {
                this.pointList = echo.data || [];
                this.map.clearOverlays();
                this.pointList.forEach(p => {
                    // 获取图片
                    this.CONSTANT.ajaxPost("AlbumSet/GetSmallImage.php", {
                        imageCode : p.LogoCode
                    }).then(smallImage => {
                        p.SmallLogoBase64 = smallImage.data;
                        const mapPoint = new window.BMapGL.Point(p.Lng, p.Lat);
                        const sizeMap = {
                            "-2" : [20, 20],
                            "-1" : [30, 30],
                            "0" : [40, 40],
                            "1" : [50, 50],
                            "2" : [60, 60]
                        }
                        const logoIcon = new window.BMapGL.Icon(p.SmallLogoBase64, new window.BMapGL.Size(...sizeMap[p.LogoSizeLevel]), {
                            anchor : new window.BMapGL.Size(25, 25),   
                            imageOffset: new window.BMapGL.Size(0, 0)
                        });
                        const marker = new window.BMapGL.Marker(mapPoint, { icon: logoIcon });
                        this.map.addOverlay(marker);
                        this.markerList.push(marker);
                        marker.addEventListener("click", () => {
                            if(this.CONSTANT.runtime === 'OTH') {
                                this.map.centerAndZoom(new window.BMapGL.Point(p.Lng - -.3, p.Lat - .15), 11); 
                            } else {
                                this.map.centerAndZoom(new window.BMapGL.Point(p.Lng - -1.25, p.Lat - 2), 8); 
                            }
                            this.panelShow(p);
                        });
                        marker.addEventListener("mouseover", ev => {
                            this.hintPanelShow(ev.target, p);
                        });
                    });
                });
            });
        },
        panelShow(pointInfo) {
            const sizeMap = {
                "-2" : [20, 20],
                "-1" : [30, 30],
                "0" : [40, 40],
                "1" : [50, 50],
                "2" : [60, 60]
            }
            if(this.currentLabel) {
                this.map.removeOverlay(this.currentLabel);
                this.currentLabel = null;
            }
            const labelContent = this.CONSTANT.runtime === 'OTH'
            ? `
                <style>
                    div[map-lable-flag]:hover > h4, div[map-lable-flag]:hover > h5 {
                        text-decoration: underline;
                    }
                </style>
                <div map-lable-flag>
                    <h4 style="font-size : 18px; color : rgba(255,255,255,.8);">${ decodeURIComponent(pointInfo.Title_ZH) }</h4>
                    ${ pointInfo.Title_EN ? "<h5 style='font-size : 16px; color : rgba(255,255,255,.8);'>" + decodeURIComponent(pointInfo.Title_EN) + "</h5>" : "" }
                    ${ pointInfo.Content_ZH ? "<h6 style='font-size : 14px; color : rgba(255,255,255,.8);'>" +  decodeURIComponent(pointInfo.Content_ZH) + "</h6>" :""}
                    ${ pointInfo.Content_EN ? "<h6 style='font-size : 14px; color : rgba(255,255,255,.8);'>" + decodeURIComponent(pointInfo.Content_EN) + "</h6>" : "" }
                    <h6 style="font-size : 14px; color : rgba(255,255,255,.8);">美国大学排名：#${ pointInfo.Rank }</h6>
                    <div class="label-code-${ pointInfo.Code }"></div>
                </div>
            `
            : `
                <style>
                    div[map-lable-flag]:hover > h4, div[map-lable-flag]:hover > h5 {
                        text-decoration: underline;
                    }
                </style>
                <div map-lable-flag>
                    <h4 style="font-size : 10px; color : rgba(255,255,255,.8);">${ decodeURIComponent(pointInfo.Title_ZH) }</h4>
                    ${ pointInfo.Title_EN ? "<h5 style='font-size : 10px; color : rgba(255,255,255,.8);'>" + decodeURIComponent(pointInfo.Title_EN) + "</h5>" : "" }
                    ${ pointInfo.Content_ZH ? "<h6 style='font-size : 8px; color : rgba(255,255,255,.8);'>" +  decodeURIComponent(pointInfo.Content_ZH) + "</h6>" :""}
                    ${ pointInfo.Content_EN ? "<h6 style='font-size : 8px; color : rgba(255,255,255,.8);'>" + decodeURIComponent(pointInfo.Content_EN) + "</h6>" : "" }
                    <h6 style="font-size : 8px; color : rgba(255,255,255,.8);">美国大学排名：#${ pointInfo.Rank }</h6>
                    <div class="label-code-${ pointInfo.Code }"></div>
                </div>
            `;
            const label = new window.BMapGL.Label(labelContent, {
                position : new window.BMapGL.Point(pointInfo.Lng, pointInfo.Lat),
                offset :  new window.BMapGL.Size(sizeMap[pointInfo.LogoSizeLevel][0] - 10, -sizeMap[pointInfo.LogoSizeLevel][0] / 2 - 3)
            });
            const labelStype = {
                border : "none",
                padding : "12px",
                "border-radius" : "8px",
                "box-shadow" : "3px 3px 3px 3px rgba(0,0,0,.05)",
                "background-color" : "#2b2b2b",
                opacity : .8,
                "backdrop-filter": "blur(20px)",
                cursor : "pointer"
            };
            if(pointInfo.Content_EN || pointInfo.Content_ZH) {
                labelStype.width = this.CONSTANT.runtime === 'OTH' ? "600px" : "250px",
                labelStype["white-space"] = "normal";
            }
            label.setStyle(labelStype);
            this.map.addOverlay(label);
            this.currentLabel = label;
            const $labelDOM = document.querySelector(`.label-code-${ pointInfo.Code }`).parentNode;
            //console.log($labelDOM);
            DOMFlash($labelDOM).show(500);
            this.CONSTANT.setUserLog("1", `观看了校点【${ decodeURIComponent(pointInfo.Title_ZH) }】的信息面板。`);
            label.addEventListener("click", () => {
                DOMFlash($labelDOM).show(500, () => {
                    this.toDetail(pointInfo);
                });
            });
        },
        hintPanelShow(evTarget, pointInfo) {
            const sizeMap = {
                "-2" : [20, 20],
                "-1" : [30, 30],
                "0" : [40, 40],
                "1" : [50, 50],
                "2" : [60, 60]
            }
            if(this.currentHintLabel) this.map.removeOverlay(this.currentHintLabel);
            if(this.currentLabel) return;
            const labelContent = `
                <h4 style="font-size : 18px; color : rgba(255,255,255,.8);">${ decodeURIComponent(pointInfo.Title_ZH) }</h4>
                ${ pointInfo.Title_EN ? "<h5 style='font-size : 16px; color : rgba(255,255,255,.8);'>" + decodeURIComponent(pointInfo.Title_EN) + "</h5>" : "" }
                <h6 style="font-size : 14px; color : rgba(255,255,255,.8);">美国大学排名：#${ pointInfo.Rank }</h6>
            `;
            const label = new window.BMapGL.Label(labelContent, {
                position : new window.BMapGL.Point(pointInfo.Lng, pointInfo.Lat),
                offset :  new window.BMapGL.Size(sizeMap[pointInfo.LogoSizeLevel][0] - 10, -sizeMap[pointInfo.LogoSizeLevel][0] / 2 - 3)
            });
            const labelStype = {
                border : "none",
                padding : "12px",
                "border-radius" : "8px",
                "box-shadow" : "3px 3px 3px 3px rgba(0,0,0,.05)",
                "background-color" : "#2b2b2b",
                opacity : .8,
                "backdrop-filter": "blur(20px)",
                "pointer-events" : "none"
            }
            label.setStyle(labelStype);
            this.map.addOverlay(label);
            this.currentHintLabel = label;
            this.CONSTANT.setUserLog("1", `鼠标划过了校点【${ decodeURIComponent(pointInfo.Title_ZH) }】，并查看了它的简略信息。`);
            evTarget.addEventListener("mouseout", () => {
                if(this.currentHintLabel) this.map.removeOverlay(this.currentHintLabel);
            })
        },
        toDetail(pointInfo) {
            // console.log(pointInfo);
            const $a = document.createElement("a");
            const md5 = window.md5("sakura" + pointInfo.Code + "hime");
            $a.href = window._CONFIG_.collegeURL + "?c=" + pointInfo.Code + "-" + md5 + "&z=" + this.ARGS.z;
            $a.target = "_blank";
            this.CONSTANT.setUserLog("2", `进入了校点【${ decodeURIComponent(pointInfo.Title_ZH) }】的【学校简介】页面。`);
            $a.click();
        },
        // 返回大地图
        zoomBack(evTarget) {
            DOMFlash(evTarget).show(500, () => {
                let [lng, lat, zoomLevel] = this.ARGS.z.split("_");
                this.map.centerAndZoom(new window.BMapGL.Point(lng, lat), zoomLevel); 
            });
        },
    },
    data() { return {
        map : null,
        currentLabel : null,
        currentHintLabel : null,
        markerList : [],
        pointList : []
    }},
    mounted() {
        this.init();
    }
}